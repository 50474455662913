import NotFound from './views/NotFound'
import RSS from './views/RSS'
import Tmp from './views/Tmp'

// import Page from './views/Page'
// import Pool from './views/Pool'
// import Items from './views/Items'
// import Import from './views/Import'
// import TestMysql from './views/TestMysql'

/*
meta params currently in use:   
  requiresAuth (if true, invisible unless logged in) (not currently used)
  noNav (don't include in site navigation (mainmenu.vue))

Components can read meta params as this.$route.meta.foo

*/

// Condensed form of paths.
//  * name is the nav link text
//  * id is the page id on the mysql "page" table
//  * path is the route
//  * tag is the pool tag that should be preloaded for the page

// Nested routes MUST follow parent routes in this array; MainMenu.vue depends on this  (i.e. don't put /photos/foo/ before /photos/)
// TODO get this from sql instead of hardcoding? overkill for now, not goign to be constantly rearranging the site structure, but the data has to be in mysql for prerender to work

var paths = [{
  name: "Big Things",
  id: "big",
  path: "/photos/travel/big/",
  tag: "big"
}, {
  name: "Australia",
  id: "australia",
  path: "/photos/travel/australia/",
  tag: "australia"
}, {
  name: "Berkshires",
  id: "berkshire",
  path: "/photos/travel/berkshire/",
  tag: "berkshire"
}, {
  name: "Cruise",
  id: "cruise",
  path: "/photos/travel/cruise/",
  tag: "cruise"
}, {
  name: "Fire",
  id: "burningman",
  path: "/photos/burningman/",
  tag: "fire"
}, {
  name: "Decay",
  id: "decay",
  path: "/photos/decay/",
  tag: "decay"
}, {
  name: "Doggity",
  id: "doggity",
  path: "/photos/doggity/",
  tag: "doggity"
}, {
  name: "Family",
  id: "family",
  path: "/photos/family/",
  tag: "family"
}, {
  name: "Holiday",
  id: "holiday",
  path: "/photos/holiday/",
  tag: "holiday"
}, {
  name: "Portraits",
  id: "portraits",
  path: "/photos/portraits/",
  tag: "portraits"
}, {
  name: "Travel",
  id: "travel",
  path: "/photos/travel/",
  tag: "travel"
}, {
  name: "Archive",
  id: "archive",
  path: "/photos/archive/",
  tag: "photos"
}, {
  name: "Blog",
  id: "blog",
  path: "/blog/",
  tag: "blog"
}, {
  name: "Geek",
  id: "geek",
  path: "/geek/",
  tag: "geek"
}, {
  name: "Photos",
  id: "photos",
  path: "/photos/"
}, {
  name: "Paintings",
  id: "paintings",
  path: "/paintings/",
  tag: "painting"
}, {
  name: "Portfolio",
  id: "portfolio",
  path: "/portfolio/"
}, {
  name: "Home",
  id: "Home",
  path: "/"
}];

// build real routes array:
const routesArr = [];

for (let page of paths) {
  let newRoute = {
    path: page.path,
    name: page.name,
    component: () => import( /* webpackChunkName: "page" */ './views/Page'),
    props: {
      id: page.id
    }
  }
  if (page.tag) {
    newRoute.props.tag = page.tag
    newRoute.meta = {
      tag: page.tag
    } // pool pages will use for next/prev links
  }

  routesArr.push(newRoute)

  if (page.tag) {
    routesArr.push({
      path: page.path + ':slug(.*).html',
      component: () => import( /* webpackChunkName: "pool" */ './views/Pool'),
      props: (route) => ({
        slug: route.params.slug,
        tag: page.tag
      }),
      meta: {
        noNav: true,
        tag: page.tag // pool pages will use for next/prev links
      }
    })
  }
}

routesArr.push({
  path: '/index.xml',
  component: RSS,
  meta: {
    noNav: true
  }
})
routesArr.push({
  path: '/blog.xml',
  component: RSS,
  meta: {
    noNav: true,
    filter: 'blog'
  }
})
routesArr.push({
  path: '/images.xml',
  component: RSS,
  meta: {
    noNav: true,
    filter: 'photos'
  }
})
routesArr.push({
  path: '/geek.xml',
  component: RSS,
  meta: {
    noNav: true,
    filter: 'geek'
  }
})

routesArr.push({
  path: '/tmp',
  component: Tmp,
  meta: {
    noNav: true,
  }
})

routesArr.push({
  path: '*',
  component: NotFound,
  meta: {
    noNav: true
  }
})

export default routesArr;