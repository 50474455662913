<template>
  <div>
    <div v-if="isReady">
      <button @click="fixImages">Go</button>
    </div>

    <!--     <h2>Duplicate Slugs</h2>
    {{duplicates}}
    <div v-for="(val, id) in duplicates" :key="id">
      <router-link target="_new" :to="'photos/archive/'+id + '.html'">{{id}} {{pool[id].title}}</router-link>
    </div>
 -->
  </div>
</template>

<script>
/*
This was used temporaily to make bulk changes to pool items.  Keeping around in case I need to do that sort of thign again.

Hint: next time back up the database first
*/

import {
	mapState
} from 'vuex'

export default {
	name: 'Tmp',
	components: {},
	data() {
		return {
			isReady: false,
			slugs: {},
			duplicates: {}
		}
	},
	beforeMount() {
		this.$store.dispatch('pool/getAll');
	},
	beforeDestroy() {
		document.removeEventListener("keydown", this.keyEventWatcher)
	},
	watch: {
		pool() {
			console.log("...")
			this.isReady = true
		}
	},
	methods: {
		// setSlug() {
		// 	this.slugs = {};
		// 	for (let id in this.pool) {
		// 		if (id) {
		// 			let p = this.pool[id];
		// 			if (typeof (p) === 'object') {
		// 				// if (!p.slug) {
		// 				let slug = this.filters.slug(p.title);
		// 				if (this.slugs[slug]) {
		// 					console.warn("Duplicate slug: ", this.slugs[slug], p.id, p.title);
		// 					this.duplicates[this.slugs[slug]] = 1;
		// 					this.duplicates[p.id] = 1;

		// 				} else {
		// 					this.slugs[slug] = id;
		// 				}

		// 				let newP = Object.assign({}, p);

		// 				newP.slug = slug;

		// 				// this.$store.dispatch('pool/edit', newP);

		// 				// }
		// 			}
		// 		}
		// 	}

		// }
		fixImages() {
			for (let id in this.pool) {
				let p = this.pool[id];
				if (p.body.indexOf("../img/pool") > -1) {

					let newP = Object.assign({}, p);
					newP.body = p.body.replace(/\.\.\/img\/pool/, '/pool');
					this.$store.dispatch('pool/edit', newP);
				}
			}
		}
	},
	computed: {
		...mapState('pool', {
			'pool': 'pool'
		})
	}
}
</script>

<style>

</style>