<template lang="html">
  <div id="signin">
    <template v-if="inDevMode">
      <div class="login">
        <button v-if="currentUser" @click="logout">Log out</button>
        <button v-if="!currentUser && !edit" @click="start">Admin</button>
      </div>
      <div v-if="edit" class="modal small">
        <label><span class="label">User:</span> <input ref="userfield" v-model="user"></label>
        <label><span class="label">Pwd:</span> <input v-model="pwd"></label>
        <!-- <label><span class="label">nonce:</span> <input v-model="nonce"></label> -->
        <!-- <label><span class="label">New pwd:</span> <input v-model="newpwd"></label> -->
        <button @click="login">Sign in</button>
      </div>
    </template>
  </div>
</template>
<script>
import {
	mapState
} from 'vuex';

export default {
	name: 'auth',
	data() {
		return {
			user: '',
			pwd: '',
			nonce: '',
			newpwd: '',
			edit: false,
			inDevMode: (process.env.NODE_ENV === 'development')
		}
	},
	computed: {
		...mapState('user', {
			'currentUser': 'currentUser'
		}),
	},
	mounted() {

		// this.refs.userfield.focus();
	},
	methods: {
		start() {
			this.edit = true;
			this.$nextTick(() => {
				this.$refs.userfield.focus()
			})
		},
		login() {
			this.edit = false;

			this.$http.post('http://danielbeck.net/api/user.php', {
				u: this.user,
				p: this.pwd,
				new: this.newpwd,
				n: this.nonce
			}).then(response => {
				this.newpwd = '';
				this.nonce = response.body;
				this.pwd = '';
				this.$store.dispatch('user/setCurrentUser', {
					user: this.user,
					nonce: this.nonce
				});
				window.scrollTo(0, 0);
				// TODO: check that nonce on every write to the database
			})
		},
		logout() {
			this.$store.dispatch('user/logout');
			this.user = '';
			this.pwd = '';
			this.nonce = '';
			this.newpwd = '';
			this.edit = false;
		}
	}
}
</script>
<style>
#signin .login {position:absolute; top:0.5em;right:1em;}
</style>