<template>
  <div id="app">
    <sign-in></sign-in>
    <main-menu></main-menu>
    <article id="body" role="main">
      <router-view :key="$route.path"></router-view>
    </article>
    <foot></foot>
  </div>
</template>
<script>
import router from '@/router';
import Mainmenu from '@/components/MainMenu';
import Foot from '@/components/Footer';
import SignIn from '@/components/SignIn';

export default {
  name: 'app',
  components: {
    'main-menu': Mainmenu,
    router,
    Foot,
    SignIn
  }
}
</script>
<style src="@/helpers/global.scss" lang="scss"></style>

<style lang="scss">
article {
  position: relative;
  margin: 0 1em 0 5em;
  max-width: 40em;
  font-family: 'Alegreya Sans', sans-serif;
  font-size: 21px;
  line-height: 1.4em;

  xmp,
  code {
    font-size: 14px;
    line-height: 1.2em;
    max-width: 100%;
    overflow: scroll;
  }

  a {
    text-decoration: none;
    border-bottom: 1px solid #DDD;
    color: #00F
  }

  a:hover {
    border-bottom: 2px solid #00F;
  }


  img {
    max-width: 100%;
  }



  .heros {
    a {
      border: none;
    }

    a:hover {
      border: none;
    }

    img {
      width: 100%;
    }
  }

}

@media screen and (max-width: 600px) {
  article {
    margin-left: 1em;
  }
}

.label {
  font-family: 'Asap';
  font-style: italic;
  font-size: 0.7em;
  vertical-align: bottom;
  opacity: 0.7;
}

h1,
h2,
h3,
h4 {
  font-style: italic;
  font-family: "Asap", sans-serif;
}


h1 {
  font-size: 1.5em;
  line-height: 1.17em;
}

h2 {
  font-size: 1.17em;
  line-height: 1.12em;
}

h3 {
  font-size: 1.12em;
  line-height: 1em;
}

h4 {
  font-size: .83em;
  line-height: 1em;
}


.modal {
  border: 1px solid;
  position: fixed;
  box-shadow: 0 0 500px #000;
  background-color: #FFF;
  padding: 1em;
  top: 2%;
  left: 2%;
  right: 2%;
  max-height: 90vh;
  overflow: scroll;
  z-index: 2
}

.modal.small {
  top: 20%;
  left: 20%;
  right: 20%;
}

</style>