
// TEMP duplicate of the filter in @/helpers/:
const fromDatetimeLocal = function (input) {
	// convert output of datetime-local field to a UTC timestamp
	var t = new Date(new Date(input + "Z").getTime() + (new Date().getTimezoneOffset() * 60000));
	if (isNaN(t.getTime())) {
		return undefined
	} else {
		return t.getTime()
	}
}


const prepForStorage = (context, data) => {
	if (!context.rootState.user.currentUser.user) {
		console.warn("Can't prep item for storage; no current user");
		return false;
	}

	data._user = context.rootState.user.currentUser.user;

	if (typeof(data._created) === 'object') {
		data._created = data._created.getTime();
	} else {
		data._created = new Date().getTime();
	}

	data._edited = new Date().getTime();

	if (data.date) {
		data.date = fromDatetimeLocal(data.date);
	}

	if (data.img) {
		data.img = data.img.replace(/^\/pool\//,'')
	}

	// don't do this, otherwise it's impossible to clear fields intentionally
	// for (let k in data) {
	// 	if (data[k] == null) {delete data[k]}
	// }

	return data;
}

export default {
	prepForStorage
}