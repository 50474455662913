'use strict';
import Vue from 'vue';
Vue.prototype.filters = {};
/* 
Define all filters as a Vue.prototype.filters.foo which can be called as "component.filters.foo(data,params...)" 
and as a normal Vue.filter which can be called pipe-style.
This is because "normal" filters don't work in v-html, only in handlebars. 
*/

const dateFormat = require('dateformat');

const formatDate = (d, fmt) => {
	fmt = fmt || "dddd, mmmm dS, yyyy, h:MM:ss TT";
	fmt = (fmt === 'day') ? "mmmm d" : fmt;
	fmt = (fmt === 'date') ? "m/d/yy" : fmt;
	if (isFinite(d)) {
		if (!(d instanceof Date)) {
			d = new Date(d);
		}
		return dateFormat(d, fmt);
	} else {
		return undefined
	}
}
Vue.prototype.filters.formatDate = formatDate;
Vue.filter('formatDate', formatDate);

const datetimeLocal = function (d) {
	// convert Date or utc timestamp to a format useable by datetime-local input fields
	if (isFinite(d)) {
		if (!(d instanceof Date)) {
			d = new Date(d);
		}
		return dateFormat(d, "yyyy-mm-dd'T'HH:MM:ss");
	} else {
		return undefined
	}
}
Vue.prototype.filters.datetimeLocal = datetimeLocal;
Vue.filter('datetimeLocal', datetimeLocal);

const fromDatetimeLocal = function (input) {
	// convert output of datetime-local field to a UTC timestamp
	var t = new Date(new Date(input + "Z").getTime() + (new Date().getTimezoneOffset() * 60000));
	if (isNaN(t.getTime())) {
		return undefined
	} else {
		return t.getTime()
	}
}
Vue.prototype.filters.fromDatetimeLocal = fromDatetimeLocal;
Vue.filter('fromDatetimeLocal', fromDatetimeLocal);

// var marked = require('marked');
// Vue.prototype.filters.md = function(value) {
//   return marked(value || '', {
//     sanitize: true, // for now.  (This allows regular html input as well as markdown)
//     smartLists: true,
//     smartypants: true
//   });
// };
// Vue.filter('md', Vue.prototype.filters.md);

const truncate = function(text, length, clamp) {
  if (text === undefined || text === '' || text === null) return '';
  if (clamp === undefined) {clamp = '...'}
  length = length || 30;

  // strip the html.
  var tmpNode = document.createElement("DIV");
  tmpNode.innerHTML = text;
  text = tmpNode.textContent || tmpNode.innerText || "";
  
  // Short enough? 
  if (text.length <= length) return text;

  // truncate to nearest word break:
  var tcText = text.slice(0, length - clamp.length);
  var last = tcText.length - 1;
  while (last > 0 && tcText[last] !== ' ' && tcText[last] !== clamp[0]) last -= 1;

  // Fix for case when text doesn't have any spaces:
  last = last || length - clamp.length;

  tcText = tcText.slice(0, last);
  return tcText + clamp;
};

Vue.prototype.filters.truncate = truncate;
Vue.filter('truncate', truncate);

const slug = function(text) {
  if (text === undefined || text === '' || text === null) return '';
  text = text.toLowerCase().replace(/[^\w\s-]/g,'');
  text=truncate(text,60,'').replace(/\s+/g,'-').replace(/-+/,'-');
  return text;
}

Vue.prototype.filters.slug = slug;
Vue.filter('slug', slug);
