<template>
  <xmp id="RSS" ref="rss">
    <!-- 
			this is the most ass-backwards way to generate an RSS feed you've ever heard of, I promise.
			Vue templates aren't designed to output anything other than HTML, so all the rss tags want to be components;
			instead of messing with that I just generate it as a string and force it into the dom.

			also this will all wind up inside the router view. See vue.config.js prerender's postprocess, which will remove that.

			This should have been built as a completely separate script outside of vue.

     -->
  </xmp>
</template>

<script>
import {
	mapState
} from 'vuex'

export default {
	name: 'rss',
	components: {},
	data() {
		return {}
	},
	computed: {
		...mapState('pool', {
			'pool': 'pool'
		})
	},
	beforeMount() {
		this.init();
	},
	mounted() {},
	methods: {
		init() {
			this.$store.dispatch('pool/getAll', this.tag)
			document.addEventListener('pool-getAll', this.renderReady, {
				once: true
			});
		},
		filterFeed(id) {
			if (this.$route.meta.filter) {
				return this.pool[id].tags.includes(this.$route.meta.filter)
			} else {
				return this.pool[id].tags.includes("blog") || this.pool[id].tags.includes("geek") || this.pool[id].tags.includes("photos")
			}
		},
		renderReady() {
			let ret = `<rss version="2.0"><channel><title>Danielbeck.net</title><link>http://danielbeck.net</link><description>Your source for all things Daniel and Beck</description>`
			let feedkeys = Object.keys(this.pool)
				.filter(this.filterFeed)
				.sort((a, b) => {
					return this.pool[b].date - this.pool[a].date
				})
				.slice(0, 25);

			for (let k of feedkeys) {
				let p = this.pool[k];
				ret += '<item>';
				ret += '<pubDate>' + this.filters.formatDate(p.date, 'ddd, dd mmm yyyy HH:MM:ss o') + '</pubDate>';
				ret += '<title>' + p.title + '</title>';

				let url = "";
				if (p.tags.includes("blog")) {
					url = 'http://danielbeck.net/blog/' + k + '.html'
				}
				if (p.tags.includes("geek")) {
					url = 'http://danielbeck.net/geek/' + k + '.html'
				}
				if (p.tags.includes("photos")) {
					url = 'http://danielbeck.net/photos/archive/' + k + '.html'
				}
				ret += '<link>' + url + '</link><guid isPermaLink="true">' + url + '</guid>';
				ret += '<source url="http://danielbeck.net">Syndicated from danielbeck.net</source>';
				let bodytext = p.body || '';
				if (p.img) {
					bodytext += '<img src="http://danielbeck.net' + p.img + '">';
				}
				ret += '<description><![CDATA[' + bodytext + ']]></description></item>';
			}
			ret += '</channel></rss>';

			this.$el.innerText = ret;

			document.dispatchEvent(new Event('prerender-trigger'))

		}
	}
}
</script>