import 'es6-promise/auto'; // polyfill for IE
import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import store from '@/store';
import routes from './router';
import VueResource from 'vue-resource'; // http library
import Fingerprint from 'fingerprintjs2'; // for analytics
import '@/helpers';
import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

Vue.use(VueResource);
Vue.config.productionTip = false

// ------------------------------------------------------------------------------------------------------------------------------------
// Router config

Vue.use(VueRouter);
const router = new VueRouter({
  routes: routes,
  mode: 'history'
});

// Check before each page load whether the page requires authentication
router.beforeEach((to, from, next) => {
  console.log("routing to " + to.path);
  const currentUser = store.getters['user/getCurrentUser'];
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !currentUser) {
    //next('/sign-in');
    // TODO
  } else {
    // set page title 
    document.title = (to.matched[0].meta) ? to.matched[0].meta.title : '';
    next();
  }
});

router.afterEach((to, from) => {
  // safety valve, in case I forget to dispatch this event on a particular page:
  window.setTimeout(() => {
    document.dispatchEvent(new Event('prerender-trigger'))
  }, 5000);

  // scroll to page anchor. Need to wait until after page render
  window.setTimeout(() => {
    if (to.hash) {
      window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        left: 0
      })
    } else {
      window.scrollTo({
        top: 0,
        left: 0
      })
    }

    // delay the call to analytics, too, to make sure the store exists
    analytics(to, from);
 
  }, 100);

})

// ------------------------------------------------------------------------------------------------------------------------------------
// Analytics (TODO move to separate file)

const analytics = (to, from) => {
  let fingerprint = vm.$store.getters['user/getHash'];
  if (fingerprint) {
    ping(fingerprint, "internal", to.path, from.path);
  } else {
    window.setTimeout(() => {
      Fingerprint.get(components => {
        let fingerprint = Fingerprint.x64hash128(components.map(pair => {
          return pair.value
        }).join(), 31);
        vm.$store.dispatch('user/setHash', fingerprint);
        ping(fingerprint, "external", to.path, document.referrer);
      })
    }, 500);
  }
}

const ping = (user, type, page, referrer) => {
  if (process.env.NODE_ENV === 'development') return;
  if (window.location.origin.match('local')) return;

  vm.$http.post('http://danielbeck.net/api/fp.php', {
    user,
    type,
    page,
    referrer,
    agent: navigator.userAgent,
    date: new Date().getTime()
  })
}

// ------------------------------------------------------------------------------------------------------------------------------------
// Init the app



const vm = new Vue({
  store: store,
  router: router,
  render: h => h(App)
});

router.onReady(function () {
  vm.$mount('#app', true) // second param should force "hydration" (so we don't rerender elements that are prerendered)
});