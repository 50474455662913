const state = {
	currentUser: false,
	hash: false
};

const getters = {
	getCurrentUser: state => {
		return (state.currentUser.user) ? state.currentUser : false;
	},
	getHash: state => {
		return state.hash
	}
}

const mutations = {
	setCurrentUser: (state, data) => {
		if (data && data.nonce && data.nonce != 0) {
			state.currentUser = {
				user: data.user,
				nonce: data.nonce
			};
		} else {
			state.currentUser = false;
		}
	},
	setHash: (state, hash) => {
		state.hash = hash;
	}
}

const actions = {
	setHash: (context, hash) => {
		context.commit("setHash", hash);
	},
	setCurrentUser: (context, user) => {
		context.commit('setCurrentUser', user);
	},
	logout: (context) => {
		context.commit('setCurrentUser', false);
	}
}

export default {
	state,
	mutations,
	actions,
	getters
}