import Vue from 'vue';
import StoreHelper from '../helpers';

const state = {
  pool: {},
  tagLoaded: {} // keep track of tags we've already loaded, don't go to mysql again
};

const getters = {
  bySlug: (state) => (slug) => {
    let ids = Object.keys(state.pool).filter(key => {
      return state.pool[key].slug === slug
    });
    if (ids.length == 1) {
      return state.pool[ids[0]];
    } else if (ids.length > 1) {
      console.warn("DUPLICATE SLUGS:", ids);
    }
    return {}
  }
}

const actions = {
  getAll(context) {
    // console.log("pool getAll")
    Vue.http.get('http://danielbeck.net/api/pool.php').then(response => {
      context.commit("GETPOOL", response.body)
      document.dispatchEvent(new Event('pool-getAll'));
    })
  },

  getHomepage(context) {
    Vue.http.get('http://danielbeck.net/api/homepage.php').then(response => {
      context.commit("GETPOOL", response.body)
    })
  },

  getByTag(context, tag) {
    if (state.tagLoaded[tag]) {
      // already loaded, don't go to the server again
    } else {
      Vue.http.get('http://danielbeck.net/api/pool.php?tag=' + tag).then(response => {
        context.commit("TAGLOADED", tag);
        context.commit("GETPOOL", response.body)
        document.dispatchEvent(new Event('pool-getByTag-' + tag))
      })
    }
  },

  get(context, slug) {
    // can load pool items by slug or by ID
    // Assume numeric slugs are IDs.  This is probably a terrible idea.
    let field = (isNaN(slug)) ? "slug" : "id";

    // TODO check to see if it's already in the client pool before going to server... 
    Vue.http.get('http://danielbeck.net/api/pool.php?' + field + '=' + slug).then(response => {
      context.commit("GETPOOL", response.body)
    })
  },

  edit(context, data) { // data object optionally includes an id key
    data = StoreHelper.prepForStorage(context, data);
    if (data) {
      Vue.http.post('http://danielbeck.net/api/pool-post.php', data).then(response => {
        context.commit("GETPOOL", response.body)
      })
    }
  },

  erase(context, id) { // TODO untested, doesn't check for valid user yet
    Vue.http.get('http://danielbeck.net/api/pool-delete.php?id=' + id).then(response => {
      context.commit("ERASEPOOL", id);
    })
  }
};

const mutations = {
  ERASEPOOL(state, id) {
    delete state.pool[id];
    state.pool = {
      ...state.pool
    };
  },
  GETPOOL(state, payload) {
    let newItems = {}
    for (let item of payload) {
      if (item._created) item._created = new Date(Number(item._created))
      if (item._edited) item._edited = new Date(Number(item._edited))
      if (item.date) item.date = new Date(Number(item.date))
      if (item.img) {

        // I've decided not to run pool assets through webpack, but this is how you would do it:
        // gracefully handle missing images
        // let imagepath = undefined;
        // let thumbpath = undefined;
        // try {
        //   imagepath = require('@/assets/pool/' + item.img)
        // } catch (err) {
        //   console.warn("Asset not found: ", item.img)
        //   imagepath = require('@/assets/404-image.png');
        // }
        // try {
        //   thumbpath = require('@/assets/pool/' + item.img.replace(/_image/, '_thumbnail'))
        // } catch (err) {
        //   // console.warn("Thumbnail asset not found: ", item.img)
        //   thumbpath = require('@/assets/404-thumbnail.png');
        // }
        // item.image = imagepath;
        //item.thumbnail = thumbpath;

        // TODO maybe I should find and warn about missing assets in @public, as in the webpack stuff above
        item.img = "/pool/" + item.img;
        item.thumbnail = item.img.replace(/_image/, '_thumbnail');
      }
      newItems[item.id] = item;
    }
    // merge the new items in:
    state.pool = {
      ...state.pool,
      ...newItems
    }
  },
  TAGLOADED(state, tag) {
    Vue.set(state.tagLoaded, tag, true);
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}