<template>
  <div>
    <h1>404</h1>
    Route not found
    {{$route.path}}
  </div>
</template>
<script>
export default {
	name: 'NotFound',
	mounted() {
		this.$nextTick(() => {
			document.dispatchEvent(new Event('prerender-trigger'))
		});
	}
}
</script>