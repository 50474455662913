import Vue from 'vue';
import StoreHelper from '../helpers';

const state = {
  page: {},
};

const getters = {}

const actions = {
  getAll(context) {
    Vue.http.get('http://danielbeck.net/api/page.php').then(response => {
      context.commit("GETPAGE", response.body)
      document.dispatchEvent(new Event('page-getAll'));
    })
  },

  get(context, id) {
    Vue.http.get('http://danielbeck.net/api/page.php?id=' + id).then(response => {
      context.commit("GETPAGE", response.body)
      document.dispatchEvent(new Event('page-get-' + id));
    })
  },

  edit(context, data) { // data object optionally includes an id key
    data = StoreHelper.prepForStorage(context, data);
    if (data) {
      Vue.http.post('http://danielbeck.net/api/page-post.php', data).then(response => {
        context.commit('GETPAGE', response.body)
      })
    }
  },

  erase(context, id) { // TODO untested, doesn't check for valid user yet
    Vue.http.get('http://danielbeck.net/api/page-delete.php?id=' + id).then(response => {
      console.log(response.body);
      // TODO
    })
  }
};

const mutations = {
  GETPAGE(state, payload) {
    for (let item of payload) {
      if (item._created) item._created = new Date(Number(item._created))
      if (item._edited) item._edited = new Date(Number(item._edited))
      Vue.set(state.page, item.id, item);
    }
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}