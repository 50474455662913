/* Imports all other files in this directory, sets namespaced=true by default */


const files = require.context('.', false, /\.js$/)
const modules = {}

files.keys().forEach(key => {
	if (key === './index.js') return
	modules[key.replace(/(\.\/|\.js)/g, '')] = { 
		...files(key).default,
		namespaced: true
	}
})

export default modules