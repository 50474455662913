<template>
  <nav id="mainmenu">
    <a id="skipnav" href="#body">Skip navigation</a>
    <ul class="home">
      <li class="active">
        <router-link to="/">Home</router-link>
      </li>
    </ul>
    <ul>
      <li v-for="item in nav[0]" :key="item.path" :class="item.classes">
        <router-link :to="item.path" exact>{{item.name}}</router-link>
      </li>
    </ul>
    <div v-show="nav[1]">
      <ul>
        <li v-for="item in nav[1]" :key="item.path" :class="item.classes">
          <router-link :to="item.path" exact>{{item.name}}</router-link>
        </li>
      </ul>
    </div>
    <div v-show="nav[2]">
      <ul>
        <li v-for="item in nav[2]" :key="item.path" :class="item.classes">
          <router-link :to="item.path" exact>{{item.name}}</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
import SiteRoutes from '@/router';
import SignIn from '@/components/SignIn'

export default {
  name: 'Mainmenu',
  components: {
    SignIn
  },
  data() {
    return {}
  },
  computed: {
    siteRoutes() {
      return SiteRoutes.filter(r => {
        if (r.meta && r.meta.noNav) {
          return false;
        }
        return true;
      })
    },
    nav() {
      let nav = [
        [],
        [],
        []
      ];
      let routeparts = this.$route.path.split('/').filter(Boolean);

      for (let r of this.siteRoutes) {
        let parts = r.path.split('/').filter(Boolean);
        let link = {
          path: r.path,
          name: r.name,
          classes: {}
        };
        let depth = parts.length;

        if (depth === 1) { // top-level nav ("/blog", "/portfolio")
          link.classes.active = (parts[0] === routeparts[0])
          nav[parts.length - 1].push(link); // always include top-level nav links
        } else if (depth > 1) {
          // exclude links which branch too far off the current route.
          // For example, if the current route is "/photos/portraits", we want to include "/photos/* but not "/photos/travel/*""
          if (parts[depth - 2] === routeparts[depth - 2]) {
            link.classes.active = (parts[depth - 1] === routeparts[depth - 1]);
            nav[depth - 1].push(link);
          }
        }
      }

      // in second pass, add nextIsActive and prevIsActive and build the 'classes' object.
      // Would be able to do this in CSS if there were a previous-element selector (li.active + li gets the item after li.active, but there's no way to do the revers)
      for (let i = 0; i < nav.length; i++) {
        for (let j = 0; j < nav[i].length; j++) {
          if (j < nav[i].length - 1 && nav[i][j + 1].classes.active) {
            nav[i][j].classes.nextIsActive = true;
          }
          if (j > 0 && nav[i][j - 1].classes.active) {
            nav[i][j].classes.prevIsActive = true;
          }
        }
      }

      if (nav[2].length == 0) {
        delete nav[2]
      }
      if (nav[1].length == 0) {
        delete nav[1]
      }

      return nav;
    }
  },
  methods: {}
}
</script>
<style lang="scss">
#mainmenu {
  font-family: "Asap";
  line-height: 1.3em;
  margin: 0;
  padding-top: 0.5em;

  #skipnav {
    position: absolute;
    left: -10000px;
  }

  #skipnav:focus {
    position: static;
    margin-left: 1.5em;
  }

  a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.9em;
    color: #000;
  }

  .active a {
    font-weight: bold;
  }

  ul.home .active a {
    font-weight: normal;
  }

  ul {
    list-style: none;
    margin: 0.5em 0 0 0;
    padding: 0 1em;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  }


  li {
    display: inline-block;
    padding: 0 15px;
  }

  li.active {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid transparent;
    border-radius: 0.3em 0.3em 0 0;
    border-bottom: 1px solid #FFF;
    margin-bottom: -1px;
  }

  ul.home li.active {
    border: none;
  }
}

@media screen and (max-width: 750px) {
  #mainmenu {
    li {
      padding: 0 7px
    }

    li.active {
      border: none;
    }
  }
}

@media screen and (max-width: 450px) {
  #mainmenu {
    li {
      display: none
    }

    li.active,
    // li.prevIsActive, 
    // li.nextIsActive,
    ul:last-child li {
      display: inline-block;
    }

    // ul:not(:last-child) li.prevIsActive a:after {font-size: 16px; content: " >>";}
    // ul:not(:last-child) li.nextIsActive a:before {font-size: 16px; content: "<< ";}
    // ul:not(:last-child) li.prevIsActive a,
    // ul:not(:last-child) li.nextIsActive a {font-size: 0;}
  }
}

</style>