import Vue from 'vue'
import Vuex from 'vuex'

import modules from './modules'

Vue.use(Vuex)


/* I don't think we can use this, but it's here for future demonstration
const testPlugin = store => {
	store.subscribe((mutation, state) => {
		console.log("Store mutation", mutation, state);
		// return false;  (prevents mutation)

		if (mutation.payload.key) {
			// Can change the payload like this:
			mutation.payload.record.addedFromPlugin = 1
			// which allows to easily add data on storage.

			// But can't figure out how to find which part of `state` is being modified here,
			// so can't read existing data! no comparisons to original, increment, etc
			// (vuexfire complicates this)

			// This isn't quite correct afaict, and comes too late to e.g. prepend the store module name:
			console.log("path is ", mutation.payload.key)
			console.log("record is ", mutation.payload.record[".key"])
		}
	})
}
*/

export default new Vuex.Store({
	modules,
	strict: process.env.NODE_ENV !== 'production',
	// plugins: [testPlugin]
})
